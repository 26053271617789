import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "new-release-of-entur-gtfs-feed"
    }}>{`New release of Entur GTFS feed`}</h1>
    <p>{`Entur plans to roll out an updated version of their GTFS feed on November 3rd 2021.
This is a technical update that will allow us to export GTFS data originated from new data providers. It provides also improvements and fixes minor issues with the existing feed.`}</p>
    <h1 {...{
      "id": "release-notes"
    }}>{`Release notes:`}</h1>
    <h2 {...{
      "id": "improvements"
    }}>{`Improvements:`}</h2>
    <ul>
      <li parentName="ul">{`In some instances unused stops were exported in stops.txt. They are now filtered out.`}</li>
      <li parentName="ul">{`The shape_dist_traveled in stop_times.txt and shapes.txt had unnecessary digits (sub-meter precision). This field is now rounded to the meter.`}</li>
      <li parentName="ul">{`Columns that do not contain any value are skipped.`}</li>
    </ul>
    <h2 {...{
      "id": "bug-fix"
    }}>{`Bug Fix:`}</h2>
    <ul>
      <li parentName="ul">{`The wheelchair_boarding field in stops.txt was inconsistent with data in the Norwegian Stop Registry. This is now fixed.`}</li>
    </ul>
    <h2 {...{
      "id": "other-changes"
    }}>{`Other changes:`}</h2>
    <ul>
      <li parentName="ul">{`The column order in the GTFS export for individual operators is changed. There is no column order change for the all-Norway aggregated exports.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      